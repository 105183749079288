import { useQuery } from '@tanstack/react-query';
import { fetchUsers } from '../../services/users';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { IoIosSettings } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { userTypeEnum } from '../../models/enums';

export const UserList = ({ profile }) => {
  const usersQuery = useQuery({
    queryKey: ['users'],
    queryFn: () =>
      fetchUsers({
        limit: 1000,
        accounts:
          profile.roleId === userTypeEnum.ROOT
            ? []
            : profile.accounts.map((account) => account.id),
      }),
  });
  const columnHelper = createColumnHelper();

  const navigate = useNavigate();

  const columns = [
    columnHelper.accessor('id', {
      header: 'ID',
    }),
    columnHelper.accessor('name', {
      header: 'Nombre',
    }),
    columnHelper.accessor('firstName', {
      header: 'Apellido',
    }),
    columnHelper.display({
      id: 'actions',
      header: '',
      cell: (props) => {
        return (
          <button
            onClick={() => navigate(`user/${props.row.original.id}`)}
            row={props.row}
          >
            {' '}
            {<IoIosSettings />}{' '}
          </button>
        );
      },
    }),
  ];
  const users = usersQuery.data?.data?.data;
  const table = useReactTable({
    data: users,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  if (usersQuery.isLoading) {
    return '...';
  }

  if (usersQuery.isSuccess) {
    return (
      <>
        <table id="products">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
  }

  return 'hola';
};
