import Description from '../Description';
import StatusBadge from '../StuatusBadge';
import Avatar from '../Avatar';
import './styles.css';
import { useDispatch } from 'react-redux';
import {
  reviewVoucher,
  payVoucher,
  cancelVoucher,
} from '../../redux/vouchersSlice';
import toast from 'react-hot-toast';
import format from 'date-fns/format';
import { es } from 'date-fns/locale';
import AuthenticationString from './AuthenticationString';
import { InlineFilter } from '../../features/filters';

const OilCard = (props) => {
  const {
    withActionButtons = false,
    status,
    showMarkAsPayed = false,
    revisions,
    showConfirmPayment,
    beneficiaryName,
    department,
    quantity,
    updatedAt,
    token,
    userData,
    id,
    dispatchedAt,
    justification,
    reference,
    fetchDataByFilters,
    beneficiaryId,
    currentTab,
    productName,
    productPrice,
    totalAmount,
    showAbort,
    accountName,
    clientColor,
    showInternalData,
    organizationName,
  } = props;

  const dispatch = useDispatch();

  const handleEvaluation = async (approved) => {
    const datToReview = {
      payload: {
        approved,
        name: userData.userName,
      },
      voucherId: id,
    };
    try {
      const originalPromiseResult = await dispatch(
        reviewVoucher(datToReview)
      ).unwrap();
      toast.success(originalPromiseResult.data.message);
    } catch (errorMessage) {
      if (typeof errorMessage === 'string') toast.error(errorMessage);
    }
  };

  const handleMarkAsPayed = async () => {
    const data = {
      voucherId: id,
      payload: {
        status: 4,
      },
    };
    try {
      const originalPromiseResult = await dispatch(payVoucher(data)).unwrap();
      toast.success(originalPromiseResult.data.message);
    } catch (errorMessage) {
      if (typeof errorMessage === 'string') toast.error(errorMessage);
    }
  };

  const handleDelete = async () => {
    const data = {
      voucherId: id,
    };
    try {
      const originalPromiseResult = await dispatch(
        cancelVoucher(data)
      ).unwrap();
      toast.success(originalPromiseResult.data.message);
    } catch (errorMessage) {
      if (typeof errorMessage === 'string') toast.error(errorMessage);
    }
  };

  const BeneficiaryFilter = () => {
    return (
      <>
        <InlineFilter
          label={beneficiaryName}
          fetchDataByFilters={fetchDataByFilters}
          filters={{ beneficiaryId }}
        />
        {department && (
          <InlineFilter
            label={department}
            fetchDataByFilters={fetchDataByFilters}
            filters={{ departments: [department] }}
          />
        )}
      </>
    );
  };

  const calculatedPrice = `$${
    status < 3 ? Number(productPrice * quantity).toFixed(2) : totalAmount
  }`;

  return (
    <div className="oilCard">
      {showInternalData ? (
        <div style={{ background: clientColor, color: 'white' }} class="ribbon">
          {`${organizationName} ${accountName}`}
        </div>
      ) : null}

      <div className="oilCard__info">
        <AuthenticationString
          className="oildCard__token"
          authenticationString={token}
        />
        <div className="oilCard__title">{`${quantity} de ${productName} `}</div>
        <Description label="Status:" value={<StatusBadge status={status} />} />

        <Description label="ID:" value={id} />
        <Description
          label={status < 3 ? 'costara' : 'costo'}
          value={calculatedPrice}
        />

        {showInternalData ? (
          <Description
            label="Beneficiario:"
            value={
              currentTab === 'overview' ? (
                <BeneficiaryFilter />
              ) : (
                `${beneficiaryName} | ${department}`
              )
            }
          />
        ) : null}

        {justification ? (
          <Description label="Categoria y descripcion:" value={justification} />
        ) : null}

        {reference && showInternalData ? (
          <Description label="Referencia:" value={` ${reference ?? ''}`} />
        ) : null}

        {productName ? (
          <Description label="Tipo de Producto:" value={productName} />
        ) : null}

        {showInternalData ? (
          <Description
            label="Revisado por:"
            value={
              revisions?.length > 0 ? (
                <Avatar revisions={revisions} id={id} />
              ) : (
                'Sin revisiones'
              )
            }
          />
        ) : null}

        {dispatchedAt ? (
          <Description
            label="Fecha de entrega:"
            value={format(new Date(dispatchedAt), 'EEEE Pp aaaa', {
              locale: es,
            })}
          />
        ) : null}

        <Description
          label="Ultima actualización:"
          value={format(new Date(updatedAt), 'EEEE Pp aaaa', {
            locale: es,
          })}
        />
      </div>

      {withActionButtons ? (
        <div className="oilCard__actions">
          <button
            className="oilCard__button--bad"
            onClick={() => handleEvaluation(false)}
          >
            RECHAZAR
          </button>
          <button
            className="oilCard__button--good"
            onClick={() => handleEvaluation(true)}
          >
            APROBAR
          </button>
        </div>
      ) : null}

      {showAbort && (
        <div className="oilCard__actions">
          <button
            className="oilCard__button--bad"
            onClick={() => handleDelete()}
          >
            DESTRUIR
          </button>
        </div>
      )}

      {showMarkAsPayed ? (
        <div className="oilCard__actions">
          <button onClick={handleMarkAsPayed} className="oilCard__buttonMark">
            MARCAR COMO PAGADO
          </button>
        </div>
      ) : null}

      {showConfirmPayment ? (
        <div className="oilCard__actions">
          <button className="oilCard__buttonMark">CONFIRMAR COMO PAGADO</button>
        </div>
      ) : null}
    </div>
  );
};

export default OilCard;
