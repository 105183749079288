import Input from '../Input';
import Button from '../Button';
import Select from 'react-select';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUsers } from '../../redux/usersSlice';
import { userTypeEnum } from '../../models/enums';
import { useForm, Controller } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';
import { fetchCatalog } from '../../services/products';
import { fetchUsers } from '../../services/users';

const VoucherCreateForm = ({ onSubmit, profile }) => {
  const defaultAccountId = profile.accounts?.[0].id ?? 0;
  const { control, register, handleSubmit, formState, watch } = useForm({
    quantity: '',
    beneficiaryId: '',
    categoryId: '',
    productId: '',
    useReference: '',
    accountId: defaultAccountId,
  });

  const watchAccountId = watch('accountId');

  const usersQuery = useQuery({
    queryFn: () =>
      fetchUsers({
        accounts: watchAccountId?.value ?? defaultAccountId,
      }),
    queryKey: ['users', 'accounts', watchAccountId?.value ?? defaultAccountId],
  });

  const users = useSelector((state) => state.users);
  const status = useSelector((state) => state.vouchers.create.status);
  const dispatch = useDispatch();

  useEffect(() => {
    if (profile.roleId !== userTypeEnum.TRABAJADORE)
      dispatch(
        getUsers({
          limit: 70,
        })
      );
  }, [dispatch, profile.roleId]);

  const createOptions = () => {
    if (usersQuery.isLoading || usersQuery.isFetching) return [];
    if (profile.roleId === userTypeEnum.TRABAJADORE) {
      return [
        {
          value: profile.id,
          label: `${profile.name} ${profile.firstName} (Yo)`,
        },
      ];
    }

    return usersQuery.data.data.data.map(({ id, name, firstName }) => {
      return {
        value: id,
        label: `${name.toUpperCase()} ${firstName.toUpperCase()}`,
      };
    });
  };

  const productsQuery = useQuery({
    queryKey: ['products'],
    queryFn: () => fetchCatalog({ providerId: 2 }),
  });

  const mapCatalog = (catalog) => {
    return catalog?.map((product) => ({
      label: product.name,
      value: product.id,
    }));
  };

  const createAccountOptions = () => {
    return profile.accounts.map((account) => ({
      value: account.id,
      label: account.name,
    }));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="newVoucher__content">
        <h2> Crear nuevo vale</h2>

        {profile.accounts?.length > 1 ? (
          <div className="input">
            <label htmlFor="accountId" className="input__label">
              Cuenta
            </label>
            <Controller
              name="accountId"
              control={control}
              rules={{
                required: 'Este campo es requerido',
              }}
              errorMessage={formState.errors?.accountId?.message}
              render={({ field: { onChange, onBlur, value } }) => (
                <Select
                  id="accountId"
                  placeholder="Cuenta"
                  className="react-select-container"
                  onChange={onChange}
                  options={createAccountOptions()}
                  defaultValue={'MAGNA'}
                  isLoading={users.status === 'pending'}
                />
              )}
            ></Controller>
          </div>
        ) : null}

        <Input
          type="number"
          label="Numero de litros"
          placeholder="20"
          name="quantity"
          register={register}
          errorMessage={formState.errors?.quantity?.message}
          rules={{
            required: 'Este campo es requerido',
          }}
        />

        {[
          userTypeEnum.PRESIDENTE,
          userTypeEnum.SINDICE,
          userTypeEnum.ROOT,
          userTypeEnum.TESORERE,
        ].includes(profile.roleId) ? (
          <div className="input">
            <label className="input__label"> Beneficiario: </label>
            <Controller
              name="beneficiaryId"
              control={control}
              rules={{
                required: 'Este campo es requerido',
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Select
                  placeholder="David Torres"
                  className="react-select-container"
                  onChange={onChange}
                  options={createOptions()}
                  isLoading={usersQuery.isLoading || usersQuery.isFetching}
                />
              )}
            ></Controller>
          </div>
        ) : null}

        <Input
          type="text"
          label={'Para que se va a usar?'}
          name="useReference"
          register={register}
          rules={{
            required: 'Este campo es requerido',
            minLength: {
              value: 5,
              message: 'Escriba por lo menos 5 caracteres',
            },
          }}
          errorMessage={formState.errors?.useReference?.message}
        />

        <div className="input">
          <label htmlFor="productId" className="input__label">
            Tipo de producto:
          </label>
          <Controller
            name="productId"
            control={control}
            rules={{
              required: 'Este campo es requerido',
            }}
            errorMessage={formState.errors?.productId?.message}
            render={({ field: { onChange, onBlur, value } }) => (
              <Select
                id="productId"
                placeholder="Tipo de producto"
                className="react-select-container"
                onChange={onChange}
                options={mapCatalog(productsQuery.data?.data?.data) ?? []}
                defaultValue={'MAGNA'}
                isLoading={productsQuery.isLoading || productsQuery.isFetching}
              />
            )}
          ></Controller>
        </div>

        <Button loading={status === 'pending'}>CREAR</Button>
      </div>
    </form>
  );
};

export default VoucherCreateForm;
