import { useState } from 'react';

export default function useProfile() {
  const getProfileData = () => {
    const profileData = sessionStorage.getItem('profile');
    const data = JSON.parse(profileData);
    return data;
  };

  const [profile, setProfile] = useState(getProfileData());

  const saveProfileData = (payload) => {
    sessionStorage.setItem('profile', JSON.stringify(payload));
    setProfile(payload);
  };

  return [profile, saveProfileData];
}
