import { useState, useEffect, useRef } from "react";
import "./styles.css";
const UserBar = ({ name, firstName, email, userName }) => {
  const ref = useRef();
  const [showMenu, setShowMenu] = useState(false);

  const handleLogOut = () => {
    sessionStorage.clear("profile");
    window.location.reload();
  };

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  useEffect(() => {
    function handleOutsideClick(event) {
      const clickIncludesProfileSection = event
        .composedPath()
        .includes(ref.current);
      if (!clickIncludesProfileSection && showMenu) {
        setShowMenu(false);
      }
    }

    function handleEscKeyDown(event) {
      if (event.key !== "Escape") return;
      setShowMenu(false);
    }

    if (showMenu) {
      document.addEventListener("click", handleOutsideClick);
      document.addEventListener("keydown", handleEscKeyDown);
    }

    return () => {
      document.removeEventListener("click", handleOutsideClick);
      document.removeEventListener("keydown", handleEscKeyDown);
    };
  }, [showMenu]);

  return (
    <>
      <div className="userBar">
        <button className="userAvatar" onClick={toggleMenu}>
          <div className="avatar__letters">
            {name && firstName ? `${name[0]}${firstName[0]}` : "U"}
          </div>
        </button>
      </div>

      {showMenu ? (
        <div className="profileInfo" ref={ref}>
          <div className="userInfo">
            <span className="nameLabel">
              <strong> {`${name} ${firstName}`}</strong>{" "}
            </span>
            <span className="subLabel">{userName} </span>
            <span className="subLabel"> {email} </span>
          </div>

          <ul>
            <li
              onClick={handleLogOut}
              on
              role="button"
              tabIndex={0}
              className="menu__option"
              onKeyDown={(event) => {
                if (event.key === "Enter") handleLogOut();
              }}
            >
              Cerrar sesión
            </li>
          </ul>
        </div>
      ) : null}
    </>
  );
};

export default UserBar;
