import ReactDOM from "react-dom";
import { useEffect, useRef } from "react";
import "./styles.css";
import VoucherCreateForm from "./VoucherCreateForm";

const CreateVoucher = ({ open, onClose, onSubmit, profile }) => {
  const ref = useRef();
  useEffect(() => {
    function handleOutsideClick(event) {
      const clickIncludesMainArea = event.composedPath().includes(ref.current);
      if (!clickIncludesMainArea) {
        onClose?.();
      }
    }

    function handleEsc(event) {
      if (event.key !== "Escape") return;
      onClose?.();
    }

    if (open) {
      document.addEventListener("click", handleOutsideClick);
      document.addEventListener("keydown", handleEsc);
    }

    return () => {
      document.removeEventListener("click", handleOutsideClick);
      document.removeEventListener("keydown", handleEsc);
    };
  }, [onClose, open]);

  const container = document.querySelector("#modal");

  const handleOnClose = () => {
    if (onClose) onClose();
  };

  const handleOnClickSubmit = (data) => {
    onSubmit?.(data);
  };

  return (
    open &&
    ReactDOM.createPortal(
      <section className="modal">
        <div className="newVoucher" ref={ref}>
          <button className="newVoucher__close" onClick={handleOnClose}>
            x
          </button>
          <VoucherCreateForm onSubmit={handleOnClickSubmit} profile={profile} />
        </div>
      </section>,
      container
    )
  );
};

export default CreateVoucher;
