import axios from 'axios';
import toast from 'react-hot-toast';

export const createConfig = () => {
  const profile = JSON.parse(sessionStorage.getItem('profile'));
  return {
    headers: { Authorization: `Bearer ${profile?.token}`, key: '1999' },
  };
};

const instance = axios.create();

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status !== 401) {
      return Promise.reject(error);
    }

    if (
      JSON.parse(sessionStorage.getItem('profile')) === null &&
      error.response.status === 401
    ) {
      toast.error(error.response.data.message, { id: 'login-error' });
      return Promise.reject(error);
    }

    if (
      error.response.status === 401 &&
      JSON.parse(sessionStorage.getItem('profile') !== null)
    ) {
      toast.error('La sesión ha caducado, vuelva a iniciar sesión', {
        id: 'expired',
      });
      setTimeout(() => {
        sessionStorage.clear('profile');
        window.location.reload();
      }, 1500);
    }
  }
);

export default instance;

export const BASEURL = 'https://guarded-reaches-47755.herokuapp.com/api/v1';
