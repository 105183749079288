import { Link, useNavigate } from "react-router-dom";
import Input from "../../components/Input";
import Button from "../../components/Button";
import "./styles.css";
import { toast } from "react-hot-toast";
import { login } from "../../redux/usersSlice";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";

const inputRules = {
  required: "Este campo es requerido",
};

const Login = ({ setProfile }) => {
  const { register, handleSubmit, formState } = useForm({
    username: "",
    password: "",
  });
  const dispatch = useDispatch();
  const loginStatus = useSelector((state) => state.users.login.status);
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      const originalPromiseResult = await dispatch(
        login({
          payload: {
            username: String(data.username).toLocaleLowerCase().trim(),
            password: data.password,
          },
        })
      ).unwrap();
      setProfile({
        ...originalPromiseResult.data.data,
        token: originalPromiseResult.data.token,
      });
      toast.success(originalPromiseResult.data.message);

      navigate("/");
    } catch (errorMessage) {
      sessionStorage.clear("profile");
    }
  };

  return (
    <section className="sectionLogin">
      <form className="formLogin" onSubmit={handleSubmit(onSubmit)}>
        <h1> Iniciar sesión </h1>
        <Input
          label="Usuario"
          placeholder="usuario@correo.com"
          name="username"
          autoComplete="email"
          register={register}
          rules={inputRules}
          errorMessage={formState.errors.username?.message}
        />

        <Input
          label="Contraseña"
          placeholder="Contraseña"
          type="password"
          name="password"
          autoComplete="password"
          register={register}
          rules={inputRules}
          errorMessage={formState.errors.password?.message}
        />

        <Link className="forgotPasswordLink" to={"/recovery"}>
          Olvidaste tu contraseña?
        </Link>

        <Button loading={loginStatus === "pending"} type="submit">
          INICIAR SESIÓN
        </Button>

        <div className="alternativeSection">
          <p className="forgotPasswordLabel">No tienes una cuenta?</p>
          <Link to="/sing-up" className="createAccountLink">
            Crear cuenta
          </Link>
        </div>
      </form>
    </section>
  );
};

export default Login;
