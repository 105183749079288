import Button from '../Button';
import { connect } from 'react-redux';
import success from './sucess.png';
import error from './error.png';

const Result = ({ status, onContinueClick, current, message }) => {
  if (status === 'rejected') {
    return (
      <>
        <div class="error-box">
          <h1 class="error-title">¡Algo no está bien!</h1>
          <img className="transaction__image" src={error} alt="error icon" />
          <p>{message}</p>
        </div>

        <Button onClick={onContinueClick}> INTENTAR NUEVAMENTE </Button>
      </>
    );
  }

  if (status === 'fulfilled') {
    return (
      <>
        <div class="success-box">
          <h1 class="success-title">¡Todo funcionó de maravilla!</h1>
          <img
            className="success__image"
            src={success}
            alt="swap vocuher for oil"
          />
          <p>
            Entrega{' '}
            <span class="magna-amount">{`${current.quantity} de ${current.product.name}`}</span>{' '}
            a {current.user.name} y anota el siguiente número en el ticket:
          </p>
          <p class="transaction-id">{current.id}</p>
        </div>

        <Button onClick={onContinueClick}> CANJEAR OTRO </Button>
      </>
    );
  }

  return null;
};

function mapDispatchToProps(state) {
  return {
    message: state?.vouchers?.dispatch?.message,
    current: state?.vouchers?.dispatch?.current,
  };
}

export default connect(mapDispatchToProps, {})(Result);
