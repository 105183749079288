import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { recoverAccount } from "../../redux/usersSlice";
import Button from "../../components/Button";
import Input from "../../components/Input";
import toast from "react-hot-toast";

const Recovery = () => {
  const dispatch = useDispatch();
  const status = useSelector((state) => state.users.recovery.status);
  const [ready, setReady] = useState(false);
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (email) {
      setReady(true);
    } else {
      setReady(false);
    }
  }, [email]);

  const handleOnChange = (value) => {
    setEmail(value);
  };

  const handleSubmit = async () => {
    localStorage.clear("profile");
    try {
      const originalPromiseResult = await dispatch(
        recoverAccount({ payload: { email } })
      ).unwrap();
      toast.success(originalPromiseResult.data.message);
    } catch (errorMessage) {
      toast.error(typeof errorMessage === "string" ? errorMessage : "Error");
    }
  };

  return (
    <section className="sectionLogin">
      <form onSubmit={handleSubmit} className="formLogin">
        <h1> Restablecer contraseña </h1>
        <Input
          label="Email:"
          placeholder="jperez@gmail.com"
          type="email"
          name="email"
          autoComplete="email"
          value={email}
          onChange={handleOnChange}
        />

        <Button
          loading={status === "pending"}
          type="submit"
          onClick={handleSubmit}
          disabled={!ready}
        >
          ENVIAR EMAIL
        </Button>
      </form>
    </section>
  );
};

export default Recovery;
