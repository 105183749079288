import './styles.css';
import { ChakraProvider } from '@chakra-ui/react';
import {
  Box,
  FormControl,
  FormLabel,
  Switch,
  Button,
  Stack,
  Tag,
  TagLabel,
  TagCloseButton,
  useDisclosure,
  Spinner,
} from '@chakra-ui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { fetchUser, patchUser } from '../../services/users';
import { useParams } from 'react-router-dom';
import { LinkUserAccountModal } from './LinkUserAccountModal';
import { useForm } from 'react-hook-form';
import { deleteUserAccountRelation } from '../../services/userAccounts';

export const UserAdmin = ({ profile }) => {
  const params = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const form = useForm({
    defaultValues: {
      isActive: false,
    },
  });

  const userQuery = useQuery({
    queryFn: () => fetchUser({ id: params.id }),
    queryKey: ['users', params.id],
  });

  const userMutation = useMutation({
    mutationKey: 'userMutation',
    mutationFn: (payload) =>
      patchUser({
        id: userQuery.data.data.id,
        payload: payload,
      }),
  });

  const deleteUserAccountMutation = useMutation({
    mutationKey: 'deleteUserAccount',
    mutationFn: (id) => {
      deleteUserAccountRelation({ id });
    },
    onSuccess: () => {
      userQuery.refetch();
    },
  });

  const onSubmit = (data) => {
    userMutation.mutate(data);
  };

  if (userQuery.isLoading) {
    return '...';
  }

  if (userQuery.isSuccess) {
    const userData = userQuery.data.data;
    form.setValue('isActive', userData.isActive);
    return (
      <ChakraProvider>
        <LinkUserAccountModal
          profile={profile}
          isOpen={isOpen}
          onClose={onClose}
          userId={params.id}
        />
        <Box p={4}>
          <h2> Configurar usuario</h2>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <FormControl display="flex" alignItems="center" mb={4} size="lg">
              <FormLabel htmlFor="user-active" mb="0" size="lg">
                Activo
              </FormLabel>
              <Switch
                size="lg"
                id="user-active"
                {...form.register('isActive', {
                  required: 'Este campo es obligatorio',
                })}
              />
            </FormControl>
            <Box mt={4} mb={4}>
              {userQuery.isFetching || deleteUserAccountMutation.isLoading ? (
                <Spinner />
              ) : (
                <Box>
                  <Stack direction="row">
                    {userData.accounts.map(({ name, id, UserAccounts }) => (
                      <Tag
                        size="lg"
                        key={id}
                        borderRadius="full"
                        variant="solid"
                        colorScheme="gray"
                      >
                        <TagLabel>{name}</TagLabel>
                        <TagCloseButton
                          onClick={() =>
                            deleteUserAccountMutation.mutate(UserAccounts.id)
                          }
                        />
                      </Tag>
                    ))}
                  </Stack>
                  <Button mt={2} type="button" onClick={onOpen}>
                    Agregar otra cuenta
                  </Button>
                </Box>
              )}
            </Box>

            <Button
              isLoading={userMutation.isLoading}
              colorScheme="green"
              type="submit"
            >
              Guardar
            </Button>
          </form>
        </Box>
      </ChakraProvider>
    );
  }
};
