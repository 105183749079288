import { useQuery } from '@tanstack/react-query';
import { fetchCatalog } from '../../services/products';
import './styles.css';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { EditableCell } from './EditableCell';

export const Products = () => {
  const providerId = 2;
  const productsQuery = useQuery({
    queryKey: ['products', providerId],
    queryFn: () => fetchCatalog({ providerId }),
  });

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor('id', {
      header: 'ID',
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('name', {
      header: 'Detalles del producto',
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('price', {
      header: 'Precio (MNX)',
      footer: (info) => info.column.id,
      cell: (props) => <EditableCell {...props} />,
    }),
  ];

  const table = useReactTable({
    data: productsQuery.data?.data?.data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  if (productsQuery.isFetching || productsQuery.isLoading) {
    return 'loading';
  }

  if(productsQuery.isError) {
    return  '❌ Algo salio mal al intentar leer estos datos';
  }

  return (
    <div>
      <div class="container">
        <table id="products">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr class="product-card" key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
