import "./styles.css";
import Input from "../Input";
import Result from "./Result";
import Button from "../Button";
import swap from "../../assets/swap.jpg";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dispatchVoucher } from "../../redux/vouchersSlice";
import { toast } from "react-hot-toast";
import { useForm } from "react-hook-form";

const VoucherTransactor = ({ onContinueClick }) => {
  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      rabbitToken: "",
    },
  });
  const status = useSelector((state) => state.vouchers?.dispatch?.status);
  const dispatch = useDispatch();
  const [showTransactor, setShowTransactor] = useState(true);
  const [success, setShowSuccess] = useState(false);

  const toggleTransactor = () => {
    setShowTransactor(!showTransactor);
  };

  const handleOnContinue = () => {
    toggleTransactor();
    reset();
  };

  const onSubmit = async (data, event) => {
    try {
      const originalPromiseResult = await dispatch(
        dispatchVoucher({ ...data })
      ).unwrap();
      toast.success(originalPromiseResult.data.message);
      setShowSuccess(true);
      toggleTransactor();
    } catch (errorMessage) {
      setShowSuccess(false);
      toggleTransactor();
    }
  };

  return (
    <section className="transaction">
      {showTransactor ? (
        <>
          <img
            className="transaction__image"
            src={swap}
            alt="swap voucher for oil"
          />

          <h1> Canjear voucher </h1>
          <p className="transaction__instructions">
            Por favor introduzca la serie de autenticación del voucher. Si esta
            es valida se hará la transacción.
          </p>

          <form className="transaction__form" onSubmit={handleSubmit(onSubmit)}>
            <Input
              label="Serie de autenticación"
              maxLength={99}
              name="rabbitToken"
              register={register}
            />
            <Button loading={status === "pending"}>VALIDAR</Button>
          </form>
        </>
      ) : (
        <Result
          status={status}
          onContinueClick={handleOnContinue}
          success={success}
        />
      )}
    </section>
  );
};

export default VoucherTransactor;
