import axios from './axios';
import { createConfig, BASEURL } from './axios';

const URL = BASEURL + '/userAccount';

const postUserAccountRelation = async ({ userId, accountId }) => {
  const response = await axios.post(
    URL,
    {
      userId,
      accountId,
    },
    createConfig()
  );
  return response;
};

const deleteUserAccountRelation = async ({ id }) => {
  const response = await axios.delete(`${URL}/${id}`, createConfig());
  return response;
};

export { postUserAccountRelation, deleteUserAccountRelation };
