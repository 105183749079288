import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchUsers,
  postUser,
  restorePassword,
  recovery,
  postLogin,
} from "../services/users";

const initialState = {
  entities: [],
  status: undefined,
  create: { status: undefined },
  reset: { status: undefined },
  recovery: { status: undefined },
  login: { status: undefined },
};

const getUsers = createAsyncThunk(
  "users/fetchUsers",
  async (filters, { rejectWithValue }) => {
    const response = await fetchUsers({ ...filters });
    return response;
  }
);

const createUser = createAsyncThunk(
  "users/createUser",
  async ({ payload }, { rejectWithValue }) => {
    try {
      const response = await postUser({
        payload: {
          ...payload,
          firstName: String(payload.firstName).toUpperCase().trim(),
          name: String(payload.name).toUpperCase().trim(),
          email: String(payload.email).toLowerCase().trim(),
          accountId: 6, 
        },
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

const recoverAccount = createAsyncThunk(
  "users/recovery",
  async ({ payload }, { rejectWithValue }) => {
    try {
      const response = await recovery({
        payload,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

const resetPassword = createAsyncThunk(
  "users/resetPassword",
  async ({ payload }, { rejectWithValue }) => {
    try {
      const response = await restorePassword({
        payload,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

const login = createAsyncThunk(
  "users/login",
  async ({ payload }, { rejectWithValue }) => {
    try {
      const response = await postLogin({
        payload,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUsers.pending, (state, action) => {
      state.status = "pending";
    });
    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.status = "fulfilled";
      state.entities = action.payload.data.data;
    });
    builder.addCase(getUsers.rejected, (state, action) => {
      state.status = "rejected";
    });

    builder.addCase(createUser.pending, (state, action) => {
      state.create.status = "pending";
    });

    builder.addCase(createUser.fulfilled, (state, action) => {
      state.create.status = "fulfilled";
    });

    builder.addCase(createUser.rejected, (state, action) => {
      state.create.status = "rejected";
    });

    builder.addCase(resetPassword.rejected, (state, action) => {
      state.reset.status = "rejected";
    });

    builder.addCase(resetPassword.pending, (state, action) => {
      state.reset.status = "pending";
    });

    builder.addCase(resetPassword.fulfilled, (state, action) => {
      state.reset.status = "fulfilled";
    });

    builder.addCase(recoverAccount.rejected, (state, action) => {
      state.recovery.status = "rejected";
    });

    builder.addCase(recoverAccount.pending, (state, action) => {
      state.recovery.status = "pending";
    });

    builder.addCase(login.fulfilled, (state, action) => {
      state.login.status = "fulfilled";
    });

    builder.addCase(login.rejected, (state, action) => {
      state.login.status = "rejected";
    });

    builder.addCase(login.pending, (state, action) => {
      state.login.status = "pending";
    });

    builder.addCase(recoverAccount.fulfilled, (state, action) => {
      state.recovery.status = "fulfilled";
    });
  },
});

export { getUsers, createUser, resetPassword, recoverAccount, login };

export default usersSlice.reducer;
