import './styles.css';
import { voucherStatus } from '../../models/enums';
const StatusBadge = ({ className, status }) => {
  const getClassName = () => {
    switch (status) {
      case voucherStatus.REJECTED:
        return 'statusBadge--rejected';
      case voucherStatus.REVIEW:
        return 'statusBadge--review';
      case voucherStatus.APROBADO:
        return 'statusBadge--aproved';
      case voucherStatus.DISPATCHED:
        return 'statusBadge--dispatched';
      case voucherStatus.PAID_OUT:
        return 'statusBadge--paidOut';

      case voucherStatus.PAYMENT_CONFIRMED:
        return 'statusBadge--paymentConfirmed';
      case voucherStatus.ABORTED:
        return 'statusBadge--rejected';
      default:
        return 'transparent';
    }
  };

  const getText = () => {
    switch (status) {
      case voucherStatus.REJECTED:
        return 'Rechazado';
      case voucherStatus.REVIEW:
        return 'En Revision';
      case voucherStatus.APROBADO:
        return 'Aprobado';
      case voucherStatus.DISPATCHED:
        return 'Despachado';
      case voucherStatus.PAID_OUT:
        return 'Pagado';
      case voucherStatus.ABORTED:
        return 'Destruido';
      case voucherStatus.PAYMENT_CONFIRMED:
        return 'Confirmado';
      default:
        return '';
    }
  };
  return <div className={`statusBadge ${getClassName()}`}>{getText()}</div>;
};

export default StatusBadge;
