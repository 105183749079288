import App from './containers/App';
import {
  Login,
  ResetPassword,
  CreateAccount,
  Recovery,
} from './containers/LogIn';
import { Routes, Route } from 'react-router-dom';
import useProfile from './hooks/useProfile';
import { UserAdmin } from './components/Users/UserAdmin';
const Application = () => {
  const [profile, setProfile] = useProfile();

  if (!profile?.token) {
    return (
      <Routes>
        <Route path="/login" element={<Login setProfile={setProfile} />} />
        <Route
          path="/sing-up"
          element={<CreateAccount setProfile={setProfile} />}
        />

        <Route path="/recovery" element={<Recovery />} />
        <Route path="/reset/:token" element={<ResetPassword />} />

        <Route path="*" element={<Login setProfile={setProfile} />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="/user/:id" element={<UserAdmin profile={profile} />} />

      <Route path="/" element={<App profile={profile} />} />
    </Routes>
  );
};

export default Application;
