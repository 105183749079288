export const voucherStatus = {
  //It is pending president approbal
  REVIEW: 1,
  //It has ben signed by the president
  APROBADO: 2,
  // Voucher code has been used and gas station
  // has a claim over it
  DISPATCHED: 3,
  // The voucher was paid out by the gov.
  PAID_OUT: 4,
  // The gas station agreed on mark the voucher as paid out.
  PAYMENT_CONFIRMED: 5,
  // The voucher has been rejected by the president
  ABORTED: 20, 
  REJECTED: 500,
};

export const userTypeEnum = {
  ROOT: 0, 
  PRESIDENTE: 1,
  SINDICE: 2,
  TESORERE: 3,
  TRABAJADORE: 4,
  GASOLINERA: 5,
  GAS_STATION_WORKER: 6,
  TREASURY_WORKER: 8,
};

export const permissionsEnum = Object.freeze({
  CAN_REQUEST_PREMIUM: 1,
  CAN_VIEW_VOUCHERS_REVIEW: 2,
  CAN_VIEW_VOUCHERS_APPROVED: 3,
  CAN_VIEW_VOUCHERS_DISPATCHED: 4,
  CAN_VIEW_VOUCHERS_PAYED: 5,
  CAN_REVIEW_VOUCHERS: 6,
});
