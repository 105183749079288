import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import InfiniteScroll from 'react-infinite-scroll-component';
import { connect, useDispatch, useSelector } from 'react-redux';
import TabBar from '../../components/TabBar/TabBar';
import CircularButton from '../../components/CircularButton';
import CreateVoucher from '../../components/CreateVoucher';
import OilCardList from '../../components/OilCardList';
import VoucherTransactor from '../../components/VoucherTransactor';
import Overview from '../../components/Overview';
import UserBar from '../../components/UserBar';
import { userTypeEnum } from '../../models/enums';
import {
  getVouchers,
  createVoucher,
  resetEntities,
  downloadReport,
} from '../../redux/vouchersSlice';
import './App.css';
import loading2 from '../../assets/loading2.gif';
import Section from '../../components/Section/Section';
import { greetMainAPI } from '../../services/handshakes';
import { Products } from '../../components/Products';
import { UserList } from '../../components/Users/UserList';

function App({ vouchers, collection, profile }) {
  const dispatch = useDispatch(0);
  const [currentTab, setCurrentTab] = useState(undefined);
  const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
  const [menu, setMenu] = useState([]);
  const [query, setQuery] = useState({});
  const [userDictionary, setUserDictionary] = useState([]);
  const reportStatus = useSelector((state) => state.vouchers.report.status);

  const LIMIT = 5;

  const handleOnTabClick = (tabName) => {
    setCurrentTab(tabName);
  };

  useEffect(() => {
    window.addEventListener('visibilitychange', (type) => {
      if (document.visibilityState === 'visible') {
        greetMainAPI();
      }
    });
  }, []);

  useEffect(() => {
    if (currentTab) {
      const accounts = profile?.accounts.map((account) => account.id) ?? [];

      let effectQuery = { limit: LIMIT, offset: 0, accounts };
      const { id } = profile;
      window.scrollTo(0, 0);
      switch (currentTab) {
        case 'myTickets':
          effectQuery = { ...effectQuery, status: [1, 2], beneficiaryId: id };
          break;

        case 'overview':
          effectQuery = { ...effectQuery, status: [1, 2, 3] };
          break;

        case 'gas-station-overview':
          effectQuery = { ...effectQuery, status: [3] };
          break;

        case 'myHistory':
          effectQuery = {
            ...effectQuery,
            status: [3, 4, 5],
            beneficiaryId: id,
          };
          break;

        case 'review':
          effectQuery = { ...effectQuery, status: [1] };
          break;

        case 'history':
          effectQuery = { ...effectQuery, status: [3, 4, 5] };
          break;

        case 'gasHistory':
          effectQuery = { ...effectQuery, status: [3, 4, 5] };
          break;

        case 'dispatched':
          effectQuery = { ...effectQuery, status: [3] };
          break;

        case 'readyToPay':
          effectQuery = { ...effectQuery, status: [3] };
          break;

        default:
          break;
      }

      dispatch(resetEntities());

      dispatch(getVouchers({ ...effectQuery }));
      setQuery({ ...effectQuery });
    }
  }, [dispatch, currentTab, profile]);

  useEffect(() => {
    let menu = [];
    switch (profile?.roleId) {
      case userTypeEnum.PRESIDENTE:
      case userTypeEnum.SINDICE:
      case userTypeEnum.ROOT:
        menu = [
          { name: 'review', text: 'REVISAR' },
          { name: 'myTickets', text: 'CARTERA' },
          { name: 'overview', text: 'PANORAMA' },
          { name: 'users', text: 'USUARIOS' },
        ];
        break;

      case userTypeEnum.TESORERE:
        menu = [
          { name: 'review', text: 'REVISAR' },
          { name: 'readyToPay', text: 'PAGAR' },
          { name: 'myTickets', text: 'MI CARTERA' },
          { name: 'overview', text: 'PANORAMA' },
        ];
        break;

      case userTypeEnum.TRABAJADORE:
        menu = [
          { name: 'myTickets', text: 'MI CARTERA' },
          { name: 'myHistory', text: 'HISTORIAL' },
        ];
        break;

      case userTypeEnum.GASOLINERA:
        menu = [
          { name: 'dispatch', text: 'DESPACHAR' },
          { name: 'gas-station-overview', text: 'PANORAMA' },
          { name: 'gasStationProducts', text: 'PRODUCTOS' },
        ];
        break;

      case userTypeEnum.TREASURY_WORKER:
        menu = [
          { name: 'readyToPay', text: 'PAGAR' },
          { name: 'overview', text: 'PANORAMA' },
          { name: 'myTickets', text: 'MI CARTERA' },
        ];
        break;

      case userTypeEnum.GAS_STATION_WORKER:
        menu = [
          { name: 'dispatch', text: 'DESPACHAR' },
          { name: 'dispatched', text: 'DESPACHADOS' },
        ];

        break;

      default:
        menu = [
          { name: 'a', text: '1' },
          { name: 'dispatched', text: 'DESPACHADOS' },
        ];
        break;
    }

    setCurrentTab(menu[0].name);

    setMenu(menu);
  }, [profile?.roleId, profile, profile?.permissions]);

  const toggleCreateModal = () => {
    if (!createModalIsOpen) {
      window.scrollTo(0, 0);
    }
    setCreateModalIsOpen(!createModalIsOpen);
    if (
      profile.roleId === userTypeEnum.PRESIDENTE ||
      profile.roleId === userTypeEnum.SINDICE ||
      profile.roleId === userTypeEnum.TESORERE
    ) {
      setCurrentTab('review');
    } else {
      setCurrentTab('myTickets');
    }
  };

  const handleOnSubmitNewVoucher = async (values) => {
    dispatch(
      createVoucher({
        data: {
          ...values,
          profile,
        },
      })
    )
      .unwrap()
      .then((originalPromiseResult) => {
        toggleCreateModal();

        toast.success(originalPromiseResult.data.message, {
          id: 'createVoucher',
        });
      })
      .catch((errorMessage) => {
        if (typeof errorMessage === 'string')
          toast.error(errorMessage, { id: 'createVoucher' });
      });
  };

  const fetchMoreData = async () => {
    try {
      const safeOffset = query.offset ?? 0;
      const newQuery = { ...query, offset: safeOffset + LIMIT };
      await dispatch(getVouchers(newQuery)).unwrap();
      setQuery(newQuery);
    } catch (error) {}
  };

  const fetchDataByFilters = async (filters, combineFilters, extraData) => {
    try {
      if (filters?.beneficiaryId) {
        setUserDictionary([
          ...userDictionary,
          { id: filters.beneficiaryId, name: extraData.label },
        ]);
      }
      dispatch(resetEntities());

      let newQuery = { ...filters, offset: 0, limit: LIMIT };
      if (combineFilters) {
        newQuery = { ...query, ...newQuery };
      }
      setQuery(newQuery);
      await dispatch(getVouchers({ ...newQuery })).unwrap();
    } catch (error) {
      console.error('Something went wrong');
    }
  };

  const checkIfMore = () => {
    if (vouchers.entities?.length === vouchers.total) return false;
    if (vouchers.entities?.length > vouchers.total) return false;
    return true;
  };

  const handleExportButton = async () => {
    try {
      const originalPromiseResult = await dispatch(
        downloadReport({
          ...query,
          offset: 0,
          limit: 100,
          accounts: profile.accounts.map((account) => account.id),
        })
      ).unwrap();

      // Create a blob
      const blob = new Blob([originalPromiseResult], {
        type: 'text/csv;charset=utf-8;',
      });
      const url = URL.createObjectURL(blob);

      // Create a link to download it
      const pom = document.createElement('a');
      pom.href = url;
      pom.setAttribute('download', 'Reporte');
      pom.click();
    } catch (errorMessage) {
      console.error('errorMessage', errorMessage);
      if (typeof errorMessage === 'string') toast.error(errorMessage);
    }
  };

  return (
    <>
      <CreateVoucher
        onSubmit={handleOnSubmitNewVoucher}
        open={createModalIsOpen}
        onClose={toggleCreateModal}
        profile={profile}
      />

      <div className="App">
        <UserBar
          name={profile.name}
          firstName={profile.firstName}
          email={profile.email}
          userName={profile.userName}
        />
        <TabBar
          menu={menu}
          currentTab={currentTab}
          onTabClick={handleOnTabClick}
        />
        {currentTab === 'dispatch' ? (
          <VoucherTransactor />
        ) : (
          <>
            {['overview', 'gas-station-overview'].includes(currentTab) ? (
              <Overview
                profile={profile}
                fetchDataByFilters={fetchDataByFilters}
              />
            ) : null}

            {currentTab === 'gasStationProducts' ? <Products /> : null}
            {currentTab === 'users' ? <UserList profile={profile} /> : null}

            {currentTab !== 'gasStationProducts' && currentTab !== 'users' ? (
              <Section
                title="Vales de gasolina"
                subtitle={`Mostrando ${vouchers?.entities?.length ?? 0} de ${
                  vouchers.total ?? 0
                }`}
                buttonProps={
                  ['overview', 'gas-station-overview'].includes(currentTab)
                    ? {
                        loading: reportStatus,
                        label: 'Exportar a CVS',
                        onClick: handleExportButton,
                      }
                    : undefined
                }
              >
                <InfiniteScroll
                  dataLength={vouchers?.entities?.length}
                  next={fetchMoreData}
                  hasMore={checkIfMore()}
                  loader={
                    vouchers.status === 'pending' ? (
                      <div className="loader">
                        <img
                          src={loading2}
                          width={60}
                          height={60}
                          alt="Loading..."
                        />
                      </div>
                    ) : null
                  }
                >
                  <OilCardList
                    fetchDataByFilters={fetchDataByFilters}
                    status={vouchers.status}
                    userData={profile}
                    currentTab={currentTab}
                    vouchers={vouchers?.entities ?? []}
                  />
                </InfiniteScroll>
              </Section>
            ) : null}
          </>
        )}
        {!createModalIsOpen &&
        currentTab !== 'users' &&
        ![userTypeEnum.GASOLINERA, userTypeEnum.GAS_STATION_WORKER].includes(
          profile.roleId
        ) ? (
          <CircularButton onClick={toggleCreateModal} />
        ) : null}
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return {
    vouchers: state.vouchers,
  };
}

export default connect(mapStateToProps, {})(App);
