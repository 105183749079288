import './styles.css';
import Section from '../Section/Section';

import { Accounts } from './Accounts';

const Overview = ({ fetchDataByFilters, profile }) => {
  const handleAccountFilterClick = (id) => {
    fetchDataByFilters({ status: [3], accounts: [id] });
  };

  return (
    <>
      <Section title="Cuentas">
        <Accounts onFilterClick={handleAccountFilterClick} profile={profile} />
      </Section>
    </>
  );
};

export default Overview;
