import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import localizeMessage from '../libs/joi-i18n';
import {
  fetchVouchers,
  postVoucher,
  reviewVoucherService,
  dispatchVoucherService,
  downloadReportService,
  overviewService,
  payVoucherService,
  abortVoucher,
} from '../services/vouchers';

const initialState = {
  entities: [],
  count: 0,
  status: 'pending',
  dispatch: {},
  create: {
    status: undefined,
    knownBeneficiaries: [],
    voucher: undefined,
  },
  report: {
    status: undefined,
  },
  overview: {
    status: undefined,
    data: undefined,
  },
  patch: {
    status: undefined,
  },
};

const getVouchers = createAsyncThunk(
  'vouchers/fetchVouchers',

  async (filters, { rejectWithValue }) => {
    try {
      const vouchers = await fetchVouchers({ ...filters });
      return vouchers;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const createVoucher = createAsyncThunk(
  'vouchers/postVoucher',
  async ({ data, profile }, { rejectWithValue }) => {
    try {
      const beneficiaryId = data?.beneficiaryId?.value
        ? data.beneficiaryId.value
        : data.profile.id;

      const accountId = data.accountId?.value
        ? data.accountId.value
        : data.profile.accounts?.[0]?.id;

      const payload = {
        quantity: data.quantity,
        beneficiaryId,
        beneficiaryName: data?.beneficiaryId
          ? data.beneficiaryId.label
          : `${data.profile.name} ${data.profile.firstName}`,
        creatorId: data.profile.id,
        productId: String(data.productId.value).toUpperCase(),
        ownerInstitutionId: 1,
        useReference: data.useReference,
        accountId,
      };
      const response = await postVoucher({ payload });
      return response;
    } catch (error) {
      console.error(error);
      const errorMessage = localizeMessage(error.response.data.data);
      return rejectWithValue(errorMessage);
    }
  }
);

const reviewVoucher = createAsyncThunk(
  'vouchers/reviewVoucher',
  async ({ payload, voucherId }, { rejectWithValue }) => {
    try {
      const response = await reviewVoucherService({
        payload,
        voucherId,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

const payVoucher = createAsyncThunk(
  'vouchers/payVoucher',
  async ({ payload, voucherId }, { rejectWithValue }) => {
    try {
      const response = await payVoucherService({
        payload,
        voucherId,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

const cancelVoucher = createAsyncThunk(
  'vouchers/cancelVoucher',
  async ({ payload, voucherId }, { rejectWithValue }) => {
    try {
      const response = await abortVoucher({
        payload,
        voucherId,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

const dispatchVoucher = createAsyncThunk(
  'vouchers/dispatchVoucher',
  async ({ rabbitToken }, { rejectWithValue }) => {
    try {
      const response = await dispatchVoucherService({
        rabbitToken,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

const downloadReport = createAsyncThunk(
  'vouchers/downloadReport',
  async ({ ...filters }, { rejectWithValue }) => {
    try {
      const response = await downloadReportService({
        ...filters,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

const getOverview = createAsyncThunk(
  'vouchers/overview',
  async ({ payload }, { rejectWithValue }) => {
    try {
      const response = await overviewService({
        payload,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const vouchersSlice = createSlice({
  name: 'vouchers',
  initialState,
  reducers: {
    resetEntities(state) {
      state.status = 'pending';
      state.entities = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getVouchers.pending, (state, action) => {
      state.status = 'pending';
    });
    builder.addCase(getVouchers.fulfilled, (state, action) => {
      state.status = 'fulfilled';

      state.total = action.payload.total;

      const newArray = state.entities.concat(action.payload.data);

      state.entities = newArray;
    });
    builder.addCase(getVouchers.rejected, (state, action) => {
      state.status = 'rejected';
    });

    builder.addCase(createVoucher.pending, (state, action) => {
      state.create.status = 'pending';
    });
    builder.addCase(createVoucher.fulfilled, (state, action) => {
      const voucher = action.payload.data.data;
      state.entities.splice(0, 0, voucher);
      state.create.status = 'fulfilled';
    });

    builder.addCase(createVoucher.rejected, (state, action) => {
      state.create.status = 'rejected';
    });

    builder.addCase(reviewVoucher.fulfilled, (state, action) => {
      const voucher = action.payload.data.data;
      const index = state.entities.findIndex(
        (entity) => entity.id === voucher.id
      );
      if (index > -1) {
        const { updatedAt, status, revisions, token } =
          action.payload.data.data;
        const original = state.entities[index];
        state.entities.splice(index, 1, {
          ...original,
          status,
          updatedAt,
          revisions,
          token,
        });
      }
    });

    builder.addCase(dispatchVoucher.pending, (state, action) => {
      state.dispatch.status = 'pending';
    });

    builder.addCase(dispatchVoucher.fulfilled, (state, action) => {
      state.dispatch.status = 'fulfilled';
      state.dispatch.current = action.payload.data.data;
      state.dispatch.message = action.payload.data.message;
    });

    builder.addCase(dispatchVoucher.rejected, (state, action) => {
      state.dispatch.status = 'rejected';
      state.dispatch.message = action.payload;
    });

    builder.addCase(downloadReport.pending, (state, action) => {
      state.report.status = 'pending';
    });
    builder.addCase(downloadReport.fulfilled, (state, action) => {
      state.report.status = 'fulfilled';
    });
    builder.addCase(downloadReport.rejected, (state, action) => {
      state.report.status = 'rejected';
    });
    //Overview
    builder.addCase(getOverview.pending, (state, action) => {
      state.overview.status = 'pending';
    });
    builder.addCase(getOverview.fulfilled, (state, action) => {
      state.overview.status = 'fulfilled';
      state.overview.data = action.payload?.data?.data?.data;
    });
    builder.addCase(getOverview.rejected, (state, action) => {
      state.overview.status = 'rejected';
      state.overview.data = [];
    });

    builder.addCase(payVoucher.pending, (state, action) => {
      state.patch.status = 'pending';
    });
    builder.addCase(payVoucher.fulfilled, (state, action) => {
      state.patch.status = 'fulfilled';
      const voucher = action.payload.data.data;
      const index = state.entities.findIndex(
        (entity) => entity.id === voucher.id
      );
      if (index > -1) {
        state.entities.splice(index, 1, voucher);
      }
    });
    builder.addCase(payVoucher.rejected, (state, action) => {
      state.patch.status = 'rejected';
    });

    builder.addCase(cancelVoucher.pending, (state, action) => {
      state.patch.status = 'pending';
    });
    builder.addCase(cancelVoucher.fulfilled, (state, action) => {
      state.patch.status = 'fulfilled';
      const voucher = action.payload.data.data;
      const index = state.entities.findIndex(
        (entity) => entity.id === voucher.id
      );
      if (index > -1) {
        state.entities.splice(index, 1, voucher);
      }
    });
    builder.addCase(cancelVoucher.rejected, (state, action) => {
      state.patch.status = 'rejected';
    });
  },
});

export {
  payVoucher,
  getVouchers,
  createVoucher,
  reviewVoucher,
  dispatchVoucher,
  downloadReport,
  getOverview,
  cancelVoucher,
};
export const { resetEntities } = vouchersSlice.actions;

export default vouchersSlice.reducer;
