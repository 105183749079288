const translations = {
  categoryId: "categoryId",
  useReference: "referencia",
  liters: "numero de litros",
  beneficiaryId: "beneficiario",
  productId: "descripción del producto",
  //legacy 
  justification: "uso",
  vehicle: "vehículo",
  quantity: "cantidad"
};

function localizeMessage(errorData) {
  const { context } = errorData;
  const { key, limit } = context ?? {};
  const label = translations[context.key] ?? context.key;
  switch (errorData.type) {
    case "string.min":
      return `La longitud del campo "${label}" debe tener al menos ${limit} caracteres.`;

    case "number.min":
      return `El campo ${label} debe ser mayor o igual a ${limit}`;

    case "number.max":
      return `El campo "${
        translations[key] ?? key
      }" debe ser igual o menor que ${limit}.`;

    default:
      return errorData.message;
  }
}

export default localizeMessage;
