import "./styles.css";

const Description = ({ label, value }) => {
  return (
    <>
      <div className="description">
        <p className="description__key">{label} </p>
        <p className="description__value"> {value}</p>
      </div>
    </>
  );
};

export default Description;
