import "./styles.css"


const InlineFilter = ({label, fetchDataByFilters,filters }) => {
  return (
    <button className="inlineFilter" onClick={() => fetchDataByFilters({ ...filters }, true, {label})}>
      {label}
    </button>
  );
};

export default InlineFilter;
