import "./styles.css";

const TabBar = ({ currentTab = "review", onTabClick, menu }) => {
  const Tab = ({ text, name }) => {
    return (
      <li
        tabIndex={0}
        role="tab"
        className={
          currentTab === name
            ? "tabBar__listElement tabBar__listElement--active"
            : "tabBar__listElement"
        }
        onClick={() => onTabClick(name)}
        onKeyDown={(event) => {
          if (event.key === "Enter") onTabClick(name);
        }}
      >
        {text}
      </li>
    );
  };

  return (
    <nav>
      <ul role="tablist" className="tabBar__list">
        {menu?.length > 0 &&
          menu.map((element) => (
            <Tab name={element.name} key={element.name} text={element.text} />
          ))}
      </ul>
    </nav>
  );
};

export default TabBar;
