import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from "../../redux/usersSlice";
import Button from "../../components/Button";
import Input from "../../components/Input";
import { toast } from "react-hot-toast";
import { useParams, useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const status = useSelector((state) => state.users.reset.status);
  const [ready, setReady] = useState(false);
  const [form, setForm] = useState({
    password: "",
    passwordConfirmation: "",
  });
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const values = Object.values(form);
    const notIncludesEmpty = !values.includes("");
    let doublePasswordValidation;

    doublePasswordValidation = form.password === form.passwordConfirmation;

    if (doublePasswordValidation && notIncludesEmpty) {
      setReady(true);
    } else {
      setReady(false);
    }
  }, [form]);

  const handleOnChange = (value, name) => {
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = async () => {
    const payload = { ...form, token };
    delete payload.passwordConfirmation;

    try {
      const originalPromiseResult = await dispatch(
        resetPassword({ payload })
      ).unwrap();
      toast.success(originalPromiseResult.data.message);
      navigate("/login");
    } catch (errorMessage) {
      if (typeof errorMessage === "string") {
        toast.error(errorMessage ?? "");
      }
    }
  };

  return (
    <section className="sectionLogin">
      <form onSubmit={handleSubmit} className="formLogin">
        <h1> Crear nueva contraseña </h1>
        <Input
          label="Constraseña"
          type="password"
          name="password"
          value={form.password}
          onChange={handleOnChange}
        />
        <Input
          label="Confirmar contraseña"
          type="password"
          name="passwordConfirmation"
          value={form.passwordConfirmation}
          onChange={handleOnChange}
        />

        <Button
          loading={status === "pending"}
          type="submit"
          onClick={handleSubmit}
          disabled={!ready}
        >
          CAMBIAR CONTRASEÑA
        </Button>
      </form>
    </section>
  );
};

export default ResetPassword;
