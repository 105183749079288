import { configureStore } from "@reduxjs/toolkit";
import vouchersReducer from "../redux/vouchersSlice";
import usersReducer from "../redux/usersSlice";

export const store = configureStore({
  reducer: {
    vouchers: vouchersReducer,
    users: usersReducer,
  },
});
