import { useEffect, useState } from 'react';
import { updateOne } from '../../services/products';
import toast from 'react-hot-toast';

export const EditableCell = ({
  getValue,
  row: { original },
  column: { id },
  table,
}) => {
  const initialValue = getValue();
  const [value, setValue] = useState(initialValue);

  const onBlur = async () => {
    const newValue = Number(value).toFixed(2);
    setValue(newValue);

    try {
      const result = await updateOne(original.id, { price: newValue });

      if (result.status === 200) {
        toast.success('actualizado correctamente', { id: original.id });
      } else {
        toast.error(result.data.message);
      }
    } catch (error) {
      toast.success('actualizado correctamente', { id: original.id });
    }
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <input
      type="number"
      className="input__input"
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={onBlur}
    />
  );
};
