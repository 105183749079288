import { useMutation, useQueryClient } from '@tanstack/react-query';
import { postUserAccountRelation } from '../../services/userAccounts';
import {
  FormControl,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Select,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
export const LinkUserAccountModal = ({ userId, isOpen, onClose, profile }) => {
  const queryClient = useQueryClient();

  const postUserAccountMutation = useMutation({
    mutationFn: ({ userId, accountId }) =>
      postUserAccountRelation({ userId, accountId }),
    mutationKey: 'userAccountPost',
    onSuccess: () => {
      onClose();
      queryClient.refetchQueries(['users', userId]);
    },
  });

  const onSubmit = (data) => {
    postUserAccountMutation.mutate({
      userId: userId,
      accountId: data.accountId,
    });
  };

  const form = useForm({
    defaultValues: {
      accountId: '',
    },
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <ModalHeader>Asignar otra cuenta</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl display="flex" alignItems="center" mb={4} size="lg">
              <Select
                placeholder="Seleccione una"
                {...form.register('accountId', {
                  required: 'Este campo es obligatorio',
                })}
              >
                {profile.accounts.map(({ id, name }) => (
                  <option key={id} value={id}>
                    {name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button type="submit" colorScheme="blue" mr={3}>
              Agregar cuenta
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
