import "./styles.css";
const Input = ({
  type,
  label,
  placeholder,
  value,
  onChange,
  maxLength,
  name,
  autoComplete,
  errorMessage,
  register,
  rules,
}) => {
  const handleChange = (event) => {
    if (onChange) onChange(event.target.value, event.target?.name);
  };
  return (
    <div className="input">
      <label htmlFor={name} className="input__label">
        {`${label}:`}
      </label>
      <input
        id={name}
        name={name}
        className="input__input"
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        maxLength={maxLength}
        autoComplete={autoComplete}
        style={{ marginBottom: errorMessage ? "5px" : "15px" }}
        {...register?.(name, { ...rules })}
      />
      {errorMessage ? <p className="input__error"> {errorMessage} </p> : null}
    </div>
  );
};

export default Input;
