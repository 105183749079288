import "./styles.css";
import loading from "../../assets/loading.gif";

const Section = ({ title, children, buttonProps, subtitle }) => {
  const { status, label, onClick } = buttonProps ?? {};

  return (
    <section className="section">
      <h2 className="section__title">
        <>{title}</>
        {buttonProps ? (
          <button onClick={onClick}>
            {status === "pending" ? (
              <img alt="Loading..." src={loading} width={20} height={20} />
            ) : (
              <> {label}</>
            )}
          </button>
        ) : null}
      </h2>
      {subtitle ? <p className="subtitle"> {subtitle} </p> : null}

      <div className="section__children">{children}</div>
    </section>
  );
};

export default Section;
