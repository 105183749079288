import { useDispatch, useSelector } from "react-redux";
import { createUser } from "../../redux/usersSlice";
import Button from "../../components/Button";
import Input from "../../components/Input";
import { toast } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";

const inputRules = {
  required: "Este campo es requerido",
};

const CreateAccount = () => {
  const { control, register, handleSubmit, formState } = useForm({
    name: "",
    firstName: "",
    email: "",
    password: "",
    passwordConfirmation: "",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const status = useSelector((state) => state.users.create.status);

  const onSubmit = async (data) => {
    const payload = {
      ...data,
      roleId: 4,
      isActive: true,
      permissions: [],
      department: data.department.value,
      userName:
        data.name.slice(0, 1).toLocaleLowerCase() +
        data.firstName.toLowerCase(),
    };
    delete payload.passwordConfirmation;

    try {
      const originalPromiseResult = await dispatch(
        createUser({ payload })
      ).unwrap();
      toast.success(originalPromiseResult.data.message);
      navigate("/login");
    } catch (errorMessage) {
      if (typeof errorMessage === "string") {
        toast.error(errorMessage ?? "");
      }
    }
  };

  return (
    <section className="sectionLogin">
      <form onSubmit={handleSubmit(onSubmit)} className="formLogin">
        <h1> Crear cuenta </h1>
        <div className="input">
          <label htmlFor="department" className="input__label">
            Area:
            <Controller
              id="department"
              name="department"
              control={control}
              rules={{
                required: "Este campo es requerido",
              }}
              errorMessage={formState.errors?.productId?.message}
              render={({ field: { onChange, onBlur, value } }) => (
                <Select
                  placeholder="Departamento o area"
                  className="react-select-container"
                  onChange={onChange}
                  options={[
                    { value: "PRESIDENCIA", label: "PRESIDENCIA" },
                    { value: "REGIDORES", label: "REGIDORES" },
                    { value: "SEGURIDAD PUBLICA", label: "SEGURIDAD PUBLICA" },
                    {
                      value: "TRANSITO Y VIALIDAD",
                      label: "TRANSITO Y VIALIDAD",
                    },
                    {
                      value: "PROTECCION CIVIL",
                      label: "PROTECCION CIVIL",
                    },
                    {
                      value: "DIF",
                      label: "DIF",
                    },
                    {
                      value: "OBRAS PUBLICAS",
                      label: "OBRAS PUBLICAS",
                    },
                    {
                      value: "CULTURA",
                      label: "CULTURA",
                    },
                    {
                      value: "SERVICIOS PUBLICOS",
                      label: "SERVICIOS PUBLICOS",
                    },
                    {
                      value: "SINDICATURA",
                      label: "SINDICATURA",
                    },
                    {
                      value: "TESORERIA",
                      label: "TESORERIA",
                    },
                    {
                      value: "JUVENTUD Y DEPORTE",
                      label: "JUVENTUD Y DEPORTE",
                    },
                    {
                      value: "COMUNICACION",
                      label: "COMUNICACION",
                    },
                    {
                      value: "SECRETARIA MUNICIPAL",
                      label: "SECRETARIA MUNICIPAL",
                    },
                    {
                      value: "URBANISMO",
                      label: "URBANISMO",
                    },
                    {
                      value: "DIVERSIDAD SOCIAL",
                      label: "DIVERSIDAD SOCIAL",
                    },
                    {
                      value: "EDUCACION",
                      label: "EDUCACION",
                    },
                    {
                      value: "DESAROLLO SOCIAL",
                      label: "DESAROLLO SOCIAL",
                    },
                  ]}
                />
              )}
            />
          </label>
        </div>
        <Input
          label="Nombre(s)"
          placeholder="Juan"
          type="text"
          name="name"
          autoComplete="name"
          register={register}
          errorMessage={formState.errors.name?.message}
          rules={inputRules}
        />
        <Input
          label="Apellido Paterno"
          placeholder="Hernandez"
          type="text"
          name="firstName"
          autoComplete="firstName"
          register={register}
          errorMessage={formState.errors.firstName?.message}
          rules={inputRules}
        />

        <Input
          label="Correo Electrónico"
          placeholder="jond123rn@yopmail.com"
          type="email"
          name="email"
          autoComplete="email"
          register={register}
          errorMessage={formState.errors.email?.message}
          rules={inputRules}
        />

        <Input
          label="Contraseña"
          placeholder="Contraseña"
          type="password"
          name="password"
          autoComplete="password"
          register={register}
          errorMessage={formState.errors.password?.message}
          rules={{
            ...inputRules,
            minLength: {
              value: 5,
              message: "Deben ser 5 caracteres",
            },
          }}
        />

        <Input
          label="Confirmar contraseña"
          placeholder="Confirmar contraseña"
          type="password"
          name="passwordConfirmation"
          autoComplete="password"
          register={register}
          errorMessage={formState.errors.passwordConfirmation?.message}
          rules={inputRules}
        />

        <Button
          loading={status === "pending"}
          type="submit"
          onClick={handleSubmit}
        >
          CREAR CUENTA
        </Button>
        <div className="alternativeSection">
          <span>Ya tienes una cuenta?</span>
          <Link to="/login" className="createAccountLink">
            Iniciar Sesión
          </Link>
        </div>
      </form>
    </section>
  );
};

export default CreateAccount;
