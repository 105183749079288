import axios from "./axios";
import { createConfig, BASEURL } from "./axios";

const greetMainAPI = async () => {
  const response = await axios.get(`${BASEURL}/handshake`, {
    ...createConfig(),
  });
  return response;
};

export { greetMainAPI };
