import axios from './axios';
import { createConfig, BASEURL } from './axios';

const URL = BASEURL + '/vouchers';

const overviewService = async () => {
  const response = await axios.get(`${URL}/overview/`, {
    ...createConfig(),
  });
  return response;
};

const fetchVouchers = async ({
  beneficiaryId,
  status,
  limit,
  offset,
  departments,
  productId,
  accounts,
}) => {
  const response = await axios.get(URL, {
    ...createConfig(),
    params: {
      beneficiaryId,
      status,
      limit,
      offset,
      departments,
      productId,
      accounts,
    },
  });
  return response.data;
};

const postVoucher = async ({ payload }) => {
  const response = await axios.post(URL, payload, {
    ...createConfig(),
  });
  return response;
};

const reviewVoucherService = async ({ payload, voucherId }) => {
  const response = await axios.patch(`${URL}/review/${voucherId}`, payload, {
    ...createConfig(),
  });
  return response;
};

const payVoucherService = async ({ payload, voucherId }) => {
  const response = await axios.patch(`${URL}/pay/${voucherId}`, undefined, {
    ...createConfig(),
  });
  return response;
};

const abortVoucher = async ({ voucherId }) => {
  const response = await axios.patch(
    `${URL}/destroy/${voucherId}`,
    { status: 20 },
    {
      ...createConfig(),
    }
  );
  return response;
};

const dispatchVoucherService = async ({ rabbitToken }) => {
  const response = await axios.patch(`${URL}/dispatch/${rabbitToken}`, null, {
    ...createConfig(),
  });
  return response;
};

const downloadReportService = async ({
  beneficiaryId,
  status,
  limit,
  offset,
  accounts,
}) => {
  const response = await axios.get(`${URL}/download`, {
    ...createConfig(),
    params: {
      beneficiaryId,
      status,
      limit,
      offset,
      accounts,
    },
  });

  return response.data;
};

export {
  fetchVouchers,
  postVoucher,
  payVoucherService,
  reviewVoucherService,
  dispatchVoucherService,
  downloadReportService,
  overviewService,
  abortVoucher,
};
