import axios from './axios';
import { createConfig, BASEURL } from './axios';

const URL = BASEURL + '/accounts';

const fetchAccounts = async ({ limit, offset, accounts}) => {

  const response = await axios.get(URL, {
    ...createConfig(),
    params: {
      limit,
      offset,
      accounts, 
    },
  });
  return response;
};

export { fetchAccounts };
