import { useQuery } from '@tanstack/react-query';
import loading from '../../assets/loading.gif';
import { fetchAccounts } from '../../services/accounts';
import './accounts.css';

export const Accounts = ({ onFilterClick, profile }) => {

  const query = useQuery({
    queryKey: ['accounts'],
    queryFn: () => fetchAccounts({accounts: profile.accounts.map((account) => account.id)}),
    staleTime: 120000,
  });

  function formatToMoney(number) {
    const [integerPart, decimalPart] = String(number).split('.');

    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    const formattedNumber = `$${formattedInteger}.${
      decimalPart ? decimalPart : '00'
    }`;

    return formattedNumber;
  }


  if (query.isLoading || query.isFetching) {
    return (
      <div className="accountLoading">
        <img width={45} height={45} src={loading} alt="Loading..." />
      </div>
    );
  }

  const data = query.data?.data?.data;

  const handleClick = (id) => {
    onFilterClick?.(id);
  };

  if (data) {
    return (
      <div className="accounts">
        {data.map(({ balance, name, client, provider, id }) => {
          return (
            <div className="account-card">
              <div className="account-card__balance-spotlight">
                <p
                  className={`account-card__balance ${
                    balance < 0 ? 'account-card__balance--negative' : ''
                  }`}
                >
                  {formatToMoney(balance)}
                </p>
              </div>
              <div className="account-card__info">
                <button
                  onClick={() => handleClick(id)}
                  className="account-card__filter"
                >
                  {name}
                </button>
                <p className="account-card__info-text">
                  <span>{client.name}</span> - <span>{provider.name}</span>
                </p>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  if (query.isError) {
    return '❌ Algo salio mal al intentar leer estos datos';
  }
};
