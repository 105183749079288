import privateIcon from "./private.png";
import "./styles.css";

const AuthenticationString = ({ authenticationString, className }) => {
  const safeAuthenticationString = authenticationString ?? "";
  if (!safeAuthenticationString) return null;

  return !safeAuthenticationString.includes("oculta") ? (
    <span className={className}>
      <strong>{authenticationString}</strong>{" "}
    </span>
  ) : (
    <div className="hideIconContainer">
      <img
        className={className}
        src={privateIcon}
        alt="icono de serie de autenticación secreta"
        width={25}
        height={25}
      />
    </div>
  );
};

export default AuthenticationString;
