import "./styles.css";

const Avatars = ({ revisions, id }) => {
  const getInitials = (review) => {
    const [name, approved] = review.split(":");
    const initials = `${name} ${approved === "true" ? "👍🏼" : "👎🏼"}`;
    return initials;
  };

  return (
    <div className="avatars">
      {revisions?.length &&
        revisions.map((review) => {
          return (
            <div key={`${id} ${review} ${Math.random()}`} className="avatar">
              {getInitials(review)}
            </div>
          );
        })}
    </div>
  );
};

export default Avatars;
