import axios from './axios';
import { createConfig, BASEURL } from './axios';

const URL = BASEURL + '/users';

const postLogin = async ({ payload }) => {
  const response = await axios.post(`${BASEURL}/auth/login`, payload, {
    headers: {
      key: '1999',
    },
  });
  return response;
};

const restorePassword = async ({ payload }) => {
  const response = await axios.post(BASEURL + '/auth/resetPassword', payload, {
    headers: {
      key: '1999',
    },
  });
  return response;
};

const recovery = async ({ payload }) => {
  const response = await axios.post(BASEURL + '/auth/recovery', payload, {
    ...createConfig(),
  });
  return response;
};

const postUser = async ({ payload }) => {
  const response = await axios.post(URL, payload, {
    ...createConfig(),
  });
  return response;
};

const patchUser = async ({ id, payload }) => {
  const response = await axios.patch(`${URL}/${id}`, payload, {
    ...createConfig(),
  });
  return response;
};

const fetchUsers = async ({ limit, offset, accounts }) => {
  const response = await axios.get(URL, {
    ...createConfig(),
    params: {
      limit,
      offset,
      accounts,
    },
  });
  return response;
};

const fetchUser = async ({ id }) => {
  const response = await axios.get(`${URL}/${id}`, {
    ...createConfig(),
    params: {},
  });
  return response;
};

export {
  postUser,
  fetchUsers,
  restorePassword,
  recovery,
  postLogin,
  fetchUser,
  patchUser,
};
