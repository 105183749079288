import axios from './axios';
import { createConfig, BASEURL } from './axios';

const URL = BASEURL + '/products';

const fetchCatalog = async ({ limit, offset, providerId }) => {
  const response = await axios.get(`${URL}/${providerId}/catalog`, {
    ...createConfig(),
    params: {
      limit,
      offset,
    },
  });
  return response;
};

const updateOne = async (id,  payload ) => {
  const response = await axios.patch(`${URL}/${id}`, payload, {
    ...createConfig(),
  });
  return response;
};

export { fetchCatalog, updateOne };
