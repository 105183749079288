const getTabConfiguration = (tab) => {
  let configuration = {};
  switch (tab) {
    case "myTickets":
      configuration = {
        emptyMessage: "No tienes vales aun!",
        emptySubMessage:
          "Los vales que puedas cambiar por gasolina apareceran aqui",
        showCreateButton: true,
      };
      break;

    case "overview":
      configuration = {
        emptyMessage: "Que vacío esta aquí!",
        emptySubMessage: "No hay vales en movimiento",
      };
      break;

    case "myHistory":
      configuration = {
        emptyMessage: "Muy vacío todavía!",
        emptySubMessage: "Los vales que utilices quedaran guardados aquí",
      };
      break;

    case "review":
      configuration = {
        emptyMessage: "Que profesional!",
        emptySubMessage: "No tienes ningún vale pendiente por revisar",
      };
      break;

    case "history":
      configuration = {
        emptyMessage: "No tienes tickets aun!",
        emptySubMessage: "Crear un ticket",
      };
      break;

    case "gasHistory":
      configuration = {
        emptyMessage: "No hay vales guardados",
        emptySubMessage:
          "Todavia no has usado gasolina. Los vales que cambies por gasolina apareceran aqui",
      };
      break;

    default:
      configuration = {
        emptyMessage: "No tienes tickets aun!",
        emptySubMessage: "Crear un ticket",
      };
      break;
  }
  return configuration;
};

export { getTabConfiguration };
