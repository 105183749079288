import './styles.css';
import emptyOil from '../../assets/empty_gas.jpeg';
import OilCard from '../OilCard';
import {
  permissionsEnum,
  userTypeEnum,
  voucherStatus,
} from '../../models/enums';
import { getTabConfiguration } from '../../utils';

const OilCardList = ({
  vouchers,
  currentTab,
  userData,
  status,
  fetchDataByFilters,
}) => {
  //TODO: Extract this component
  const List = () => {
    return vouchers.map((voucher) => {
      const checkIfHasUserReview = () => {
        let hasReview;
        voucher.revisions.forEach((review) => {
          if (review.includes(userData.userName)) {
            hasReview = true;
          }
        });
        return hasReview;
      };
      return (
        <OilCard
          clientColor={voucher.account?.client?.color ?? ''}
          organizationName={voucher.account?.client?.name ?? ''}
          accountName={voucher.account?.name}
          currentTab={currentTab}
          fetchDataByFilters={fetchDataByFilters}
          beneficiaryId={voucher.beneficiaryId}
          reference={voucher.useReference}
          categoryId={voucher.categoryId}
          userData={userData}
          quantity={voucher.quantity}
          department={voucher?.user?.department}
          beneficiaryName={
            voucher?.beneficiaryName
              ? voucher?.beneficiaryName
              : `${voucher?.user?.name} ${voucher?.user?.firstName}`
          }
          updatedAt={voucher.updatedAt}
          token={voucher.token}
          id={voucher.id}
          key={voucher.id}
          status={voucher.status}
          productId={voucher.productId}
          withActionButtons={
            userData?.permissions.includes(
              permissionsEnum.CAN_REVIEW_VOUCHERS
            ) &&
            Number(voucher.status) === voucherStatus.REVIEW &&
            !checkIfHasUserReview() &&
            currentTab === 'review'
          }
          showMarkAsPayed={
            Number(voucher.status) === voucherStatus.DISPATCHED &&
            currentTab === 'readyToPay'
          }
          revisions={voucher.revisions}
          showConfirmPayment={false}
          dispatchedAt={voucher.dispatchedAt}
          productName={voucher.product?.name ?? ''}
          productPrice={voucher.product?.price ?? ''}
          totalAmount={voucher.totalAmount}
          showAbort={
            ([userTypeEnum.ROOT, userTypeEnum.PRESIDENTE].includes(
              userData.roleId
            ) &&
              currentTab !== 'review' &&
              voucher.status === voucherStatus.REVIEW) ||
            voucher.status === voucherStatus.APROBADO
          }
          showInternalData={
            ![
              userTypeEnum.GAS_STATION_WORKER,
              userTypeEnum.GASOLINERA,
            ].includes(userData.roleId)
          }
        />
      );
    });
  };

  if (status === 'rejected') {
    return '❌ Algo salio mal al intentar leer estos datos';
  }

  if (vouchers?.length === 0 && status === 'fulfilled') {
    const { emptyMessage, emptySubMessage } = getTabConfiguration(currentTab);
    return (
      <div className="empty">
        <img className="emptyOil" src={emptyOil} alt="emptyOil" />

        <h3 className="empty__title">{emptyMessage}</h3>
        <p className="empty__subtitle"> {emptySubMessage} </p>
      </div>
    );
  }

  return (
    <main className="cardListContainer">
      <List />
    </main>
  );
};

export default OilCardList;
