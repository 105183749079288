import "./styles.css";
import loadingGig from "../../assets/loading.gif";

const Button = ({ onClick, children, className, disabled, type, loading }) => {
  const handleOnClick = (event) => {
    onClick?.();
  };
  return (
    <button
      disabled={disabled || loading}
      className={`button ${className}`}
      onClick={handleOnClick}
      type={type}
    >
      {loading ? (
        <>
          <img src={loadingGig} height={40} alt="CARGANDO..." />
        </>
      ) : (
        children
      )}
    </button>
  );
};

export default Button;
