import "./styles.css";

const CircularButton = ({ onClick }) => {
  return (
    <button tabIndex={0} onClick={onClick} className="circularButton">
      +
    </button>
  );
};

export default CircularButton;
